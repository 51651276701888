<template>
  <swiper
    class="banner-mobile"
    loop
    :modules="modules"
    :spaceBetween="0"
    :slidesPerView="1"
    :slidesPerGroup="1"
    :centeredSlides="false"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false
    }"
    :resistance="true"
    :resistanceRatio="0.85"
    :pagination="{ clickable: true }"
  >
    <swiper-slide
      v-for="item in props.imgList"
      :key="item.imageMobUrl"
      @click="jumpFn(item.linkUrl)"
      class="v-window-item"
    >
      <img reporter-module="home_banner" reporter-appear reporter-appear-duration :src="useWebp(item.imageMobUrl)" class="w-100 h-100" />
    </swiper-slide>
  </swiper>
</template>
<script setup>
import { ref } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination, A11y, Autoplay } from "swiper/modules"
const modules = [Pagination, A11y, Autoplay]
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"

const props = defineProps({
  imgList: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["jumpFn"])

const jumpFn = (url) => {
  emit("jumpFn", url)
}
</script>
<style scoped lang="scss">
.banner-mobile {
  height: 100%;
}
:deep(.swiper-pagination) {
  bottom: 0;
  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background-color: #FFF;
    opacity: 0.6;
  }
  .swiper-pagination-bullet-active {
    background-color: #00bbef;
  }
}
</style>
